var imagesLoaded = require("imagesloaded");
var scrollbar = initScrollbar();
var mq = window.matchMedia("(max-width: 991.98px)");
var showThePopUp = false;

pointsAnimation(mq);
mq.addListener(pointsAnimation);

var projectGsap = gsap;
var tweens = {};

jQuery(document).ready(function ($) {
	if ($(".home-hero__canvas").length > 0) {
		$(".home-hero__canvas").gradient({
			colors: ["#1C0148", "#FA1D6D", "#351859", "#1C0148"],
			density: [0.06, 0.56],
			angle: 0,
			amplitude: 200,
			rotation: 80,
			speed: 10,
		});
	}

	if ($("#gradient-canvas").length > 0) {
		var gradient = new Gradient();
		gradient.initGradient("#gradient-canvas");
	}

	WebFont.load({
		custom: {
			families: ["DM Sans", "Work Sans"],
		},
		active: function () {
			initAnimation();
		},
	});

	var menuPos = document
		.getElementsByClassName("main-navigation__menu")[0]
		.getBoundingClientRect();
	$(".main-navigation__alt").css({
		top: menuPos.top,
		left: menuPos.left,
	});

	gsap.to(".main-navigation__menu", {
		opacity: 0,
		ease: "none",
		onComplete: function () {
			$(".main-navigation__alt").addClass("active");
		},
		onReverseComplete: function () {
			$(".main-navigation__alt").removeClass("active");
		},
		scrollTrigger: {
			trigger: ".site-header",
			duration: 1,
			start: "top top",
			end: "bottom top",
			ease: "none",
			scrub: true,
		},
	});

	$(".open-menu").on("click", function (e) {
		e.preventDefault();
		var parent_menu = $(this).closest("li");
		parent_menu.toggleClass("active");
		parent_menu.find("ul.sub-menu").slideToggle();
	});

	$(".price-table__switch-monthly").on("click", function () {
		$(".price-table__switch-monthly").addClass("active");
		$(".price-table__switch-annual").removeClass("active");
		$(".annual").hide();
		$(".monthly").show();
	});

	$(".price-table__switch-annual").on("click", function () {
		$(".price-table__switch-monthly").removeClass("active");
		$(".price-table__switch-annual").addClass("active");
		$(".annual").show();
		$(".monthly").hide();
	});

	var step_height = 0;
	$(".step-item__heading").each(function () {
		if ($(this).height() > step_height) {
			step_height = $(this).height();
		}
	});
	$(".step-item__heading").height(step_height);

	$(".testimonials-slider").slick({
		infinite: true,
		autoplay: true,
		autoplaySpeed: 8000,
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow:
			'<button type="button" class="slick-prev" aria-label="Prev"><svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 16.7942L1 9L14.5 1.20577L14.5 16.7942Z" stroke="#FA1D6D"/></svg></button>',
		nextArrow:
			'<button type="button" class="slick-next" aria-label="Next"><svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 1.20577L14 9L0.5 16.7942L0.5 1.20577Z" stroke="#FA1D6D"/></svg></button>',
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	if (jQuery(".digital-path").length > 0) {
		var offset = jQuery(".digital-path").offset().top;
		jQuery(".home-hero__scroll").on("click", function () {
			scrollbar.scrollTo(0, offset, 1500);
		});
	}

	if (jQuery("#one-page-checkout").length > 0) {
		var offset = jQuery("#one-page-checkout").offset().top;
		scrollbar.scrollTo(0, offset, 1500);
	}

	$(".site-menu__button").on("click", function (e) {
		e.preventDefault();
		$("#page").toggleClass("unfocus");
		$(".site-menu").toggleClass("active");
		gsap.to(".site-menu__inner", {
			duration: 0.75,
			x: 0,
			skewX: 0,
			ease: "power2.inOut",
			onComplete: function () {
				$(".site-menu__inner").toggleClass("ready");
			},
		});
	});

	$(".site-menu__close").on("click", function (e) {
		e.preventDefault();
		$("#page").toggleClass("unfocus");
		$(".site-menu__inner").toggleClass("ready");
		gsap.to(".site-menu__inner", {
			duration: 0.75,
			x: "120%",
			skewX: "10%",
			ease: "power2.inOut",
			onComplete: function () {
				$(".site-menu").toggleClass("active");
			},
		});
	});

	$(document).on("click", ".page-heading-project__breadcrumbs a", function (e) {
		e.preventDefault();
		var _this = $(this);
		$(".page-heading-project__breadcrumbs a").removeClass("active");
		$(".page-heading-project__overlay").addClass("active");

		gsap.to(".projects-holder", {
			y: 24,
			opacity: 0,
			duration: 1,
			ease: "power2.out",
		});

		var triggers = ScrollTrigger.getAll();
		triggers.forEach((trigger) => {
			trigger.kill();
		});

		$.get(_this.attr("href"), function (data) {
			_this.addClass("active");
			_this.blur();

			var newContent = $($.parseHTML(data)).find(".projects").html();
			window.history.pushState(
				"",
				_this.text() + " - 2DamCreative",
				_this.attr("href"),
			);
			document.title = _this.text() + " - 2DamCreative";

			setTimeout(function () {
				$(".projects").html(newContent);
				initAnimation();
				gsap.to(".projects-holder", {
					y: 0,
					opacity: 1,
					duration: 1,
					ease: "power2.out",
				});

				$(".page-heading-project__overlay").removeClass("active");
			}, 1000);
		});
	});

	if ($(window).width() > 991 && $("body").hasClass("single-project")) {
		imagesLoaded.makeJQueryPlugin($);
		$(".hero-project").imagesLoaded(function () {
			tweens = projectAnimation();
		});
	}
});

jQuery(window).resize(function () {
	var menuPos = document
		.getElementsByClassName("main-navigation__menu")[0]
		.getBoundingClientRect();
	$(".main-navigation__alt").css({
		top: menuPos.top,
		left: menuPos.left,
	});

	if ($("body").hasClass("single-project")) {
		if ($(window).width() > 991) {
			tweens.heroTween.scrollTrigger.enable();
			tweens.stickyTween.scrollTrigger.enable();
			tweens.openingTween.scrollTrigger.enable();

			$(".hero-project").css("opacity", 0);
			var transY =
				($(".sticky-project").outerHeight() +
					$(".hero-project").outerHeight() -
					$(".site-header__wrap").outerHeight()) *
				-1;
			$(".opening-text-project").css("opacity", 0);
			$(".hero-project").css("transform", `translateY(${transY}px)`);
			$(".hero-project").css("opacity", 1);

			//tweens.heroTween.progress(10);
			//tweens.stickyTween.progress(1);
			//tweens.openingTween.progress(10);
			//$('.pin-spacer').css('height', ( $('.sticky-project').outerHeight() + $('.hero-project').outerHeight() ) + 'px');
		} else {
			tweens.heroTween.scrollTrigger.disable();
			tweens.stickyTween.scrollTrigger.disable();
			tweens.openingTween.scrollTrigger.disable();
			$(".hero-project").attr("style", "");
			$(".opening-text-project").attr("style", "");
		}
	}
});

function projectAnimation(e) {
	var heroTween = projectGsap.from(".hero-project", {
		y:
			($(".sticky-project").outerHeight() +
				$(".hero-project").outerHeight() -
				$(".site-header__wrap").outerHeight()) *
			-1,
		ease: "none",
		scrollTrigger: {
			trigger: ".single-project",
			start: "top top",
			end: "+=" + $(".hero-project").outerHeight(),
			duration: 1,
			ease: "none",
			scrub: true,
			id: "#hero",
		},
	});
	var stickyTween = projectGsap.to(".sticky-project", {
		y: 0,
		ease: "none",
		scrollTrigger: {
			trigger: ".sticky-project",
			start: 0,
			end: "+=" + $(".hero-project").outerHeight(),
			duration: 1,
			ease: "none",
			pin: true,
			scrub: true,
			id: "#project",
		},
	});
	var openingTween = projectGsap.to(".opening-text-project", {
		opacity: 1,
		ease: "none",
		scrollTrigger: {
			trigger: ".single-project",
			start: "top top",
			end: "+=" + $(".hero-project").outerHeight(),
			duration: 1,
			ease: "none",
			scrub: true,
			id: "#text",
		},
	});

	return {
		heroTween,
		stickyTween,
		openingTween,
	};
}

function pointsAnimation(e) {
	let points = gsap.utils.toArray(".points-item");

	points.forEach(function (point, i) {
		point.addEventListener("mouseenter", function () {
			if (!e.matches) {
				gsap.to($(point).find(".points-item__subheading")[0], {
					scaleX: 1,
					opacity: 1,
					duration: 0.5,
					onComplete: function () {
						gsap.to($(point).find(".points-item__subheading span")[1], {
							opacity: 1,
							scale: 1,
							duration: 0.25,
							onComplete: function () {
								gsap.to($(point).find(".points-item__subheading span")[0], {
									y: 0,
									opacity: 1,
									duration: 0.25,
								});
							},
						});
					},
				});
			}
		});

		point.addEventListener("mouseleave", function () {
			if (!e.matches) {
				setTimeout(function () {
					gsap.to($(point).find(".points-item__subheading span")[0], {
						y: 3,
						opacity: 0,
						duration: 0.25,
						onComplete: function () {
							gsap.to($(point).find(".points-item__subheading span")[1], {
								scale: 0,
								opacity: 0,
								duration: 0.25,
								onComplete: function () {
									gsap.to($(point).find(".points-item__subheading")[0], {
										scaleX: 0,
										opacity: 0,
										duration: 0.5,
									});
								},
							});
						},
					});
				}, 300);
			}
		});
	});
}

function initAnimation() {
	var animates = gsap.utils.toArray(".animate");
	animates.forEach((animate) => {
		if ($(animate).hasClass("gsap__move-up")) {
			gsap.to(animate, {
				y: -3,
				scrollTrigger: {
					trigger: animate,
					duration: 2,
					ease: "power2.out",
					scrub: $(animate).hasClass("scrub") ? true : false,
				},
			});
		} else if ($(animate).hasClass("gsap__left-reveal")) {
			if ($(animate).hasClass("scrub")) {
				gsap.from(animate, {
					opacity: 0,
					x: "-50%",
					y: "50%",
					scrollTrigger: {
						trigger: animate,
						duration: 2,
						ease: "power2.out",
						scrub: true,
					},
				});
			} else {
				gsap.from(animate, {
					opacity: 0,
					x: -32,
					scrollTrigger: {
						trigger: animate,
						duration: 2,
						ease: "power2.out",
						scrub: false,
					},
				});
			}
		} else if ($(animate).hasClass("gsap__right-reveal")) {
			if ($(animate).hasClass("scrub")) {
				gsap.from(animate, {
					opacity: 0,
					x: "50%",
					y: "-50%",
					scrollTrigger: {
						trigger: animate,
						duration: 2,
						ease: "power2.out",
						scrub: true,
					},
				});
			} else {
				gsap.from(animate, {
					opacity: 0,
					x: 32,
					scrollTrigger: {
						trigger: animate,
						duration: 2,
						ease: "power2.out",
						scrub: false,
					},
				});
			}
		} else if ($(animate).hasClass("gsap__bottom-reveal")) {
			if ($(animate).hasClass("scrub")) {
				gsap.from(animate, {
					opacity: 0,
					y: "-50%",
					scrollTrigger: {
						trigger: animate,
						duration: 2,
						ease: "power2.out",
						scrub: true,
					},
				});
			} else {
				gsap.from(animate, {
					opacity: 0,
					y: 32,
					scrollTrigger: {
						trigger: animate,
						duration: 2,
						ease: "power2.out",
						scrub: false,
					},
				});
			}
		} else if ($(animate).hasClass("gsap__opacity")) {
			gsap.from(animate, {
				opacity: 0,
				scrollTrigger: {
					trigger: animate,
					duration: 2,
					ease: "linear",
					scrub: $(animate).hasClass("scrub") ? true : false,
				},
			});
		} else if ($(animate).hasClass("gsap__scale")) {
			gsap.from(animate, {
				scaleX: 0,
				scrollTrigger: {
					trigger: animate,
					duration: 2,
					ease: "power2.out",
					scrub: $(animate).hasClass("scrub") ? true : false,
				},
			});
		} else if ($(animate).hasClass("gsap-line")) {
			var split_text = $(animate);
			split_text.split = new SplitText(split_text, {
				type: "lines",
				linesClass: "split-line",
			});

			gsap.from(split_text.split.lines, {
				scrollTrigger: animate,
				duration: 1,
				ease: "power2.inOut",
				stagger: 0.025,
				opacity: 0,
				y: 32,
			});
		} else if ($(animate).hasClass("gsap-word")) {
			var split_text = $(animate);
			split_text.split = new SplitText(split_text, {
				type: "lines,words",
				linesClass: "split-line",
			});

			gsap.from(split_text.split.words, {
				scrollTrigger: animate,
				duration: 1,
				ease: "power2.inOut",
				stagger: 0.025,
				yPercent: 120,
			});
		} else if ($(animate).hasClass("parallax")) {
			gsap.to(animate.querySelectorAll(".parallax-content"), {
				yPercent: 18,
				scrollTrigger: {
					trigger: animate,
					duration: 2,
					ease: "none",
					scrub: true,
				},
			});
		} else if ($(animate).hasClass("gsap-svg")) {
			var _svg = $(animate).find("svg").drawsvg({
				stagger: 200,
				duration: 1500,
			});
			var progress = 0;
			var isDone = false;

			gsap.to(animate, {
				opacity: 1,
				scrollTrigger: {
					trigger: animate,
					duration: 2,
					start: "center bottom",
					end: "top top",
					ease: "linear",
					scrub: true,
					onUpdate: function (self) {
						if (progress == 0) {
							progress = self.progress;
						}

						if (!isDone) {
							if (progress >= 0.2) {
								_svg.drawsvg("animate");
								isDone = true;
							} else {
								_svg.drawsvg("progress", self.progress);
							}
						}
					},
				},
			});
		}
	});
}

function initScrollbar() {
	gsap.registerPlugin(ScrollTrigger);
	const scroller = document.querySelector(".scroller");
	const scrollbar = Scrollbar.init(scroller, {
		delegateTo: document,
		alwaysShowTracks: false,
		damping: 0.05,
	});
	ScrollTrigger.scrollerProxy(".scroller", {
		scrollTop(value) {
			if (arguments.length) {
				scrollbar.scrollTop = value;
			}
			return scrollbar.scrollTop;
		},
	});

	scrollbar.addListener(ScrollTrigger.update);
	ScrollTrigger.defaults({
		scroller: ".scroller",
		onUpdate: (self) => {
			if (self.start > 600) {
				if (!showThePopUp) {
					showThePopUp = true;
					showPopup();
				}
			}
		},
	});
	menuScrollbar = Scrollbar.init(document.querySelector(".site-menu__wrap"), {
		alwaysShowTracks: false,
		damping: 0.05,
	});

	return scrollbar;
}

var mq = window.matchMedia("(max-width: 575.98px)");
function heroAnimation() {
	jQuery("#page-loader").addClass("loaded");

	if (jQuery("body").hasClass("home")) {
		var hero_gsap = gsap;
		if (mq.matches) {
			hero_gsap.to(".home-hero__heading .gsap__move-up", {
				delay: 1,
				duration: 2,
				opacity: 1,
				ease: "power2.inOut",
			});
		} else {
			hero_gsap.to(".home-hero__heading .gsap__move-up", {
				delay: 1,
				duration: 1,
				opacity: 1,
				y: -3,
				ease: "power2.inOut",
				stagger: 0.05,
			});
			hero_gsap.to(".home-hero__heading .gsap__sequential span", {
				opacity: 1,
				scale: 1,
				ease: "power2.inOut",
				delay: 1.25,
				stagger: {
					each: 0.25,
					onComplete: function () {
						jQuery(this.targets()[0]).removeClass("no-animate");
					},
				},
				ease: "elastic",
				force3D: true,
			});
		}

		hero_gsap.to(".home-hero__subheading .gsap__reveal", {
			delay: 1.85,
			duration: 0.5,
			opacity: 1,
			ease: "power2.inOut",
			onComplete: function () {
				jQuery(this.targets()[0]).find(".dots").removeClass("no-animate");
			},
		});
		hero_gsap.to(".home-hero__subheading .gsap__move-up", {
			delay: 1.95,
			duration: 1,
			opacity: 1,
			y: -3,
			ease: "power2.inOut",
			stagger: 0.05,
		});
		hero_gsap.to(".home-hero__contact, .site-header, .home-hero__scroll", {
			delay: 2,
			duration: 0.75,
			opacity: 1,
			ease: "power2.inOut",
		});

		var dpath_gsap = gsap;
		dpath_gsap.from(
			".digital-path .two-columns__left ellipse, .digital-path .two-columns__left path",
			{
				scrollTrigger: ".digital-path",
				opacity: 0,
				scale: 0,
				ease: "linear",
				delay: 0.5,
				stagger: 0.15,
				duration: 0.15,
				force3D: true,
			},
		);

		var project_gsap = gsap.timeline({
			scrollTrigger: {
				trigger: ".projects",
				start: "top 90%",
				end: "top 55%",
				scrub: true,
			},
		});
		var project_gsap2 = gsap.timeline({
			scrollTrigger: {
				trigger: ".projects",
				start: "top 60%",
				end: "top 20%",
				scrub: true,
			},
		});
		project_gsap.to("body", { backgroundColor: "#1C0148" }, "dpath");
		project_gsap.to(
			".projects-sep__title",
			{ scale: 0.85, color: "#fff" },
			"dpath",
		);
		if (mq.matches) {
			project_gsap.to(
				".projects-sep__title span:first-child",
				{ marginRight: "0rem" },
				"dpath",
			);
			project_gsap.to(
				".projects-sep__title span:last-child",
				{ marginLeft: "0rem" },
				"dpath",
			);
		} else {
			project_gsap.to(
				".projects-sep__title span:first-child",
				{ marginRight: "15rem" },
				"dpath",
			);
			project_gsap.to(
				".projects-sep__title span:last-child",
				{ marginLeft: "8rem" },
				"dpath",
			);
		}
		project_gsap.to(".projects-sep", { height: "30vh" }, "dpath");
		project_gsap.to(".digital-path .container", { opacity: 0 }, "dpath");
		project_gsap2.from(".projects", { opacity: 0 }, "projects");
		project_gsap2.from(
			".projects-featured",
			{ x: "-50%", y: "50%" },
			"projects",
		);

		var service_gsap = gsap.timeline({
			scrollTrigger: {
				trigger: ".services",
				start: "top bottom",
				end: "top top",
				scrub: true,
			},
		});
		service_gsap.to("body", { backgroundColor: "#fff" }, "projects");
		service_gsap.from(".services .container", { opacity: 0 }, "projects");
	}
}

function stepsAnimation() {
	if (jQuery(".step").length > 0) {
		var step_gsap = gsap;
		step_gsap.from(".step-item__dot", {
			scrollTrigger: ".step",
			scale: 0,
			ease: "power2.out",
			delay: 0.5,
			stagger: {
				each: 1.5,
				onComplete: function () {
					jQuery(this.targets()[0]).removeClass("no-animate");
				},
			},
			duration: 0.5,
			force3D: true,
		});
		step_gsap.from(".step-item__heading", {
			scrollTrigger: ".step",
			opacity: 0,
			y: "50%",
			ease: "power2.out",
			delay: 1.2,
			stagger: 1.5,
			duration: 1,
			force3D: true,
		});
		step_gsap.from(".step-item__sep", {
			scrollTrigger: ".step",
			scale: 0,
			ease: "power2.out",
			delay: 1.2,
			stagger: 1.5,
			duration: 1,
			force3D: true,
		});
		step_gsap.from(".step-item__subheading", {
			scrollTrigger: ".step",
			opacity: 0,
			y: "50%",
			ease: "power2.out",
			delay: 1.2,
			stagger: 1.5,
			duration: 1,
			force3D: true,
		});
	}
}

function timelineAnimation() {
	if (jQuery(".timeline").length > 0) {
		var timeline_gsap = gsap.timeline({
			scrollTrigger: {
				trigger: ".timeline-items",
				start: "top bottom",
			},
		});

		timeline_gsap.from(".timeline-item-1 .dots", {
			delay: 0.5,
			duration: 0.5,
			scale: 0,
			ease: "power2.out",
			onComplete: function () {
				jQuery(".timeline-item .dots").addClass("no-animate");
				jQuery(".timeline-item .dots").removeClass("no-animate");
			},
		});
		gsap.from(".timeline-item-1 .timeline-item__day", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			x: -32,
			delay: 1,
			duration: 1,
			ease: "power2.out",
		});
		gsap.from(".timeline-item-1 .timeline-item__heading span", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			yPercent: 100,
			delay: 1,
			duration: 1,
			ease: "power2.out",
		});
		gsap.from(".timeline-item-1 .timeline-item__subheading", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			y: 32,
			delay: 1,
			duration: 1,
			ease: "power2.out",
		});

		timeline_gsap.from(".timeline-item-2 .timeline-item__dots ellipse", {
			opacity: 0,
			scale: 0,
			ease: "linear",
			delay: 0,
			stagger: 0.3,
			duration: 0.3,
			force3D: true,
		});
		timeline_gsap.from(".timeline-item-2 .dots", {
			delay: 0,
			duration: 0.5,
			scale: 0,
			ease: "power2.out",
			onComplete: function () {
				jQuery(".timeline-item .dots").addClass("no-animate");
				jQuery(".timeline-item .dots").removeClass("no-animate");
			},
		});
		gsap.from(".timeline-item-2 .timeline-item__day", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			x: -32,
			delay: 2.5,
			duration: 1,
			ease: "power2.out",
		});
		gsap.from(".timeline-item-2 .timeline-item__heading span", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			yPercent: 100,
			delay: 2.5,
			duration: 1,
			ease: "power2.out",
		});
		gsap.from(".timeline-item-2 .timeline-item__subheading", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			y: 32,
			delay: 2.5,
			duration: 1,
			ease: "power2.out",
		});

		timeline_gsap.from(".timeline-item-3 .timeline-item__dots ellipse", {
			opacity: 0,
			scale: 0,
			ease: "linear",
			delay: 0,
			stagger: 0.3,
			duration: 0.3,
			force3D: true,
		});
		timeline_gsap.from(".timeline-item-3 .dots", {
			delay: 0,
			duration: 1,
			scale: 0,
			ease: "power2.out",
			onComplete: function () {
				jQuery(".timeline-item .dots").addClass("no-animate");
				jQuery(".timeline-item .dots").removeClass("no-animate");
			},
		});
		gsap.from(".timeline-item-3 .timeline-item__day", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			x: -32,
			delay: 4,
			duration: 1,
			ease: "power2.out",
		});
		gsap.from(".timeline-item-3 .timeline-item__heading span", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			yPercent: 100,
			delay: 4,
			duration: 1,
			ease: "power2.out",
		});
		gsap.from(".timeline-item-3 .timeline-item__subheading", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			y: 32,
			delay: 4,
			duration: 1,
			ease: "power2.out",
		});

		timeline_gsap.from(".timeline-item-4 .timeline-item__dots ellipse", {
			opacity: 0,
			scale: 0,
			ease: "linear",
			delay: 0,
			stagger: 0.3,
			duration: 0.3,
			force3D: true,
		});
		timeline_gsap.from(".timeline-item-4 .dots", {
			delay: 0,
			duration: 1,
			scale: 0,
			ease: "power2.out",
			onComplete: function () {
				jQuery(".timeline-item .dots").addClass("no-animate");
				jQuery(".timeline-item .dots").removeClass("no-animate");
			},
		});
		gsap.from(".timeline-item-4 .timeline-item__day", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			x: -32,
			delay: 6,
			duration: 1,
			ease: "power2.out",
		});
		gsap.from(".timeline-item-4 .timeline-item__heading span", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			yPercent: 100,
			delay: 6,
			duration: 1,
			ease: "power2.out",
		});
		gsap.from(".timeline-item-4 .timeline-item__subheading", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			y: 32,
			delay: 6,
			duration: 1,
			ease: "power2.out",
		});

		timeline_gsap.from(".timeline-item-5 .timeline-item__dots ellipse", {
			opacity: 0,
			scale: 0,
			ease: "linear",
			delay: 0,
			stagger: 0.3,
			duration: 0.3,
			force3D: true,
		});
		timeline_gsap.from(".timeline-item-5 .dots", {
			delay: 0,
			duration: 1,
			scale: 0,
			ease: "power2.out",
			onComplete: function () {
				jQuery(".timeline-item .dots").addClass("no-animate");
				jQuery(".timeline-item .dots").removeClass("no-animate");
			},
		});
		gsap.from(".timeline-item-5 .timeline-item__day", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			x: -32,
			delay: 7.75,
			duration: 1,
			ease: "power2.out",
		});
		gsap.from(".timeline-item-5 .timeline-item__heading span", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			yPercent: 100,
			delay: 7.75,
			duration: 1,
			ease: "power2.out",
		});
		gsap.from(".timeline-item-5 .timeline-item__subheading", {
			scrollTrigger: ".timeline-items",
			opacity: 0,
			y: 32,
			delay: 7.75,
			duration: 1,
			ease: "power2.out",
		});
	}
}

if (document.readyState === "complete") {
	heroAnimation();
	stepsAnimation();
	timelineAnimation();
} else {
	document.onreadystatechange = function () {
		if (document.readyState == "complete") {
			heroAnimation();
			stepsAnimation();
			timelineAnimation();
		}
	};
}

jQuery(document).ready(function ($) {
	var wrapper = $(".domain-page-section");
	if ($("div").hasClass("gform_confirmation_message")) {
		wrapper.toggleClass("form-success");
	}
});

jQuery(document).ready(function ($) {
	if ($(".gform_confirmation_wrapper")[0]) {
		$(".gform_confirmation_wrapper").siblings(".form-detail").hide();
	}
});

// Function to create the HubSpot form inside the popup
function createHubSpotForm() {
	hbspt.forms.create({
		region: "na1",
		portalId: "39759722",
		formId: "10dd91c2-f019-4ed2-aa52-7f3f6a6896fc",
		target: "#custom-popup", // Specify the target element for the form
	});
}

// Function to show the popup and disable scrolling
function showPopup() {
	if (document.querySelector(".home")) {
		document.getElementById("custom-popup").style.display = "block";
		document.querySelector(".popup_wrapper").style.display = "block";
		document.body.style.overflow = "hidden"; // Disable scrolling
		createHubSpotForm();
	}
}

// Function to hide the popup and enable scrolling
function hidePopup() {
	document.getElementById("custom-popup").style.display = "none";
	document.querySelector(".popup_wrapper").style.display = "none";
	document.body.style.overflow = "auto"; // Enable scrolling
}

// Event listener to hide the popup when clicking outside of it
window.addEventListener("click", function (event) {
	if (event.target !== document.getElementById("custom-popup")) {
		hidePopup();
	}
});
